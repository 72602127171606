import React from 'react'

import { useState } from 'react';
import css from './SectionInfo.module.css'
import classNames from 'classnames';
import Spacer from '../../../components/Spacer/Spacer';

import IncreaseMoneyIcon from '../../../assets/icons/money/increase.png'
import DirectionBoardIcon from '../../../assets/icons/location/direction-board.png'
import ResearchIcon from '../../../assets/icons/interface/research.png'

import ChatIcon from '../../../assets/icons/conversation/chat.png'
import WithdrawMoneyIcon from '../../../assets/icons/money/withdrawal.png'
import EditIcon from '../../../assets/icons/interface/edit.png'

const SectionInfo = props => {
  const { intl } = props
  const [currentSection, setCurrentSection] = useState('sell')

  const tabConfiguration = {
    'sell': 3,
    'buy': 3
  }

  const tabImageConfiguration = {
    'sell': [EditIcon, ChatIcon, WithdrawMoneyIcon],
    'buy': [ResearchIcon, DirectionBoardIcon, IncreaseMoneyIcon]
  }

  const sellSliderClassNames = classNames(css.slider, {
    [css.selectSlider]: currentSection === 'sell',
  })

  const buySliderClassNames = classNames(css.slider, {
    [css.selectSlider]: currentSection === 'buy',
  })

  const sellSliderTextClassNames = classNames(css.slidertext, {
    [css.selectedSliderText]: currentSection === 'sell'
  })

  const buySliderTextClassNames = classNames(css.slidertext, {
    [css.selectedSliderText]: currentSection === 'buy'
  })

  return (
    <div className={css.root}>
      <div className={css.labelWrapper}>
        <span className={css.label}>{intl.formatMessage({ id: 'landing_page.section_info.label' })}</span>
      </div>
      <div className={css.sliderRoot}>
        <div className={sellSliderClassNames} onClick={() => setCurrentSection('sell')}>
          <span className={sellSliderTextClassNames}>
            {intl.formatMessage({ id: 'landing_page.section_info.sell' })}
          </span>
        </div>
        <div className={buySliderClassNames} onClick={() => setCurrentSection('buy')}>
          <span className={buySliderTextClassNames}>
            {intl.formatMessage({ id: 'landing_page.section_info.buy' })}
          </span>
        </div>
      </div>
      <div className={css.spacerLarge}/>
      <div className={css.sectionInfoContainer}>
        {Array.from({ length: tabConfiguration[currentSection] }, (_value, index) => {
          return (
            <div key={`${currentSection}_${index}`} className={css.sectionInfoDetails}>
              <div className={css.imageWrapper}>
                <img className={css.icon} src={tabImageConfiguration[currentSection][index]}/>
              </div>
              <div className={css.largeSpacer}/>
              <h4 className={css.sectionInfoDetailsQuestion}>
                {intl.formatMessage({ id: `landing_page.section_info.${currentSection}.question_${index}` })}
              </h4>
              <Spacer size={8}/>
              <span className={css.sectionInfoDetailsAnswer}>
                {intl.formatMessage({ id: `landing_page.section_info.${currentSection}.answer_${index}` })}
              </span>
              <Spacer size={16}/>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SectionInfo
