import React from 'react'
import { filters } from '../../../config/marketplace-custom-config'
import classNames from 'classnames'
import css from './SectionBrands.module.css'

import specialized from '../../../assets/brands/specialized.svg'
import canyon from '../../../assets/brands/canyon.svg'
import trek from '../../../assets/brands/trek.svg'
import giant from '../../../assets/brands/giant.svg'
import scott from '../../../assets/brands/scott.svg'
import cube from '../../../assets/brands/cube.svg'
import wilier from '../../../assets/brands/wilier.svg'

import { NamedLink } from '../../../components'

const BrandComponent = props => {
  const { name } = props

  const images = {
    specialized,
    giant,
    canyon,
    trek,
    scott,
    cube,
    wilier
  }

  return (
    <NamedLink name='SearchPage' params={{ type: 'bike' }} to={{ search: `pub_brand=${name}` }} className={css.brandCard}>
      <img className={css.brandLogo} src={images[name]} />
    </NamedLink>
  )
}

const SectionBrands = props => {
  const classes = classNames(css.root)
  const brands = filters
    .find(filter => filter.id == 'brand')
    .config
    .options
    .filter(brand => brand.featured == true)

  return (
    <div className={classes}>
      {brands.map(brand => {
        return <BrandComponent key={brand.key} name={brand.key}/>
      })}
    </div>
  )
}

export default SectionBrands
