import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { NamedLink } from '../../../components';

import BackgroundImage from '../../../assets/backgrounds/landing.webp'

import css from './SectionHero.module.css';
import SectionBrands from '../SectionBrands/SectionBrands';
import Spacer from '../../../components/Spacer/Spacer';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import SectionSearch from '../SectionSearch/SectionSearch';

const SectionHero = props => {
  const { rootClassName, className, handleSearchSubmit, intl, history } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroImageContainer}>
        <div className={css.overlay}/>
        <div className={css.backgroundCover}/>
        <img className={css.heroImage} src={BackgroundImage}/>
      </div>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle)}>
          {intl.formatMessage({ id: 'landing_page.section_hero.title' })}
        </h1>
        <h2 className={classNames(css.heroSubTitle)}>
          {intl.formatMessage({ id: 'landing_page.section_hero.sub_title' })}
        </h2>

        <svg className={css.divider_large} width="339" height="6" viewBox="0 0 339 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M164.278 0.667878C231.54 0.698008 278.865 0.925995 301.569 1.32838C333.41 1.89306 337.842 2.0562 338.497 2.68628C339.429 3.58136 334.692 3.79105 300.833 4.35472C215.594 5.77367 108.316 5.32358 19.2204 3.17432C1.36714 2.74308 -0.0893274 2.63628 1.44413 1.86497C3.27103 0.945947 22.6175 0.544797 62.7329 0.594471C82.403 0.618516 128.098 0.651519 164.278 0.667878Z" fill="#E5722E"/>
        </svg>
        <svg className={css.divider_small} width="208" height="6" viewBox="0 0 208 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100.356 1.48039C141.675 0.873652 170.744 0.634028 184.688 0.78442C204.242 0.995672 206.964 1.10164 207.36 1.66608C207.924 2.46791 205.012 2.70245 184.207 3.5321C131.831 5.6206 65.9343 6.22422 11.223 5.11762C0.259722 4.89536 -0.633984 4.81237 0.31529 4.0994C1.44622 3.24989 13.3346 2.70423 37.9771 2.37109C50.0602 2.20745 78.1306 1.80661 100.356 1.48039Z" fill="#E5722E"/>
        </svg>

        <Spacer size={18} />
        <span className={css.description}>{intl.formatMessage({ id: 'landing_page.section_hero.description_1' })}</span>
        <span className={css.description}>{intl.formatMessage({ id: 'landing_page.section_hero.description_2' })}</span>
        <Spacer size={32} />

        <div className={css.actionsWrapper}>
          <NamedLink name="NewListingPage" className={classNames(css.sellButton)}>
            {intl.formatMessage({ id: "landing_page.sell_button" })}
          </NamedLink>
          <div className={css.spacerMedium}/>
          <NamedLink
            name="SearchPage"
            className={classNames(css.buyButton)}
            state={{ prevPath: history.location.pathname }}
          >
            {intl.formatMessage({ id: "landing_page.view_bikes_and_parts" })}
          </NamedLink>
        </div>
      </div>
      <div className={css.heroBrands}>
        <SectionBrands/>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(withRouter)(SectionHero);
