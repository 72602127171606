import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import config from '../../config'
import { injectIntl, intlShape } from '../../util/reactIntl'
import { isScrollingDisabled } from '../../ducks/UI.duck'


import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components'
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer'

import facebookImage from '../../assets/nextveloThumbnail.jpg'

import SectionHero from './SectionHero/SectionHero'
import css from './LandingPage.module.css'
import SectionCategories from './SectionCategories/SectionCategories'
import { getListingsById } from '../../ducks/marketplaceData.duck'
import SectionRecentItems from './SectionRecentItems/SectionRecentItems'
import SectionInfo from './SectionInfo/SectionInfo'
import classNames from 'classnames'
import { createResourceLocatorString } from '../../util/routes'
import routeConfiguration from '../../routing/routeConfiguration'
import { addCurrentUserFavoriteListing, removeCurrentUserFavoriteListing } from '../../ducks/user.duck'

export const LandingPageComponent = props => {
  const { 
    history, 
    intl, 
    location, 
    scrollingDisabled,
    recentListings, 
    onLocaleChange,
    currentLocale,
    likedListingsIds,
    addUserFavorite,
    removeUserFavorite,
    currentUserId,
    recentPartListings
  } = props

  const siteTitle = config.siteTitle
  const schemaTitle = intl.formatMessage({ id: 'Landing_page.schema_title' })
  const schemaDescription = intl.formatMessage({ id: 'landing_page.schema_description' })
  const host = typeof window !== 'undefined' && window.location && window.location.host;
  const schemaImage = `${host}${facebookImage}`

  const handleSubmit = (values) => {
    const { history } = props
    const searchParams = { keywords: values?.keywords }

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams))
  }

  const renderRecentListings = (listings, label) => (
    <>
      <div className={css.mostRecentItemsContainer}>
        <label className={css.mostRecentItemsContainerTitle}>{label}</label>
        <SectionRecentItems 
          recentListings={listings}
          intl={intl}
          likedListingsIds={likedListingsIds}
          addUserFavorite={addUserFavorite}
          removeUserFavorite={removeUserFavorite}
          currentUserId={currentUserId}
        />
      </div>
    </>
  )

  const renderBuyButton = (listingType, label) => (
    <>
      <div className={css.searchItemsContainer}>
        <NamedLink
          name="SearchPage"
          params={{ type: listingType }}
          className={classNames(css.heroButton)}
          state={{ prevPath: history.location.pathname }}
        >
          {label}
        </NamedLink>
      </div>
    </>
  )

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale}/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
              handleSearchSubmit={handleSubmit}
              intl={intl}
            />
          </div>
          <div className={css.categoryContainer}>
            <label className={css.categoryContainerTitle}>
              {intl.formatMessage({ id: 'landing_page.categories_label' })}
            </label>
            <SectionCategories intl={intl}/>
          </div>

          { recentListings && renderRecentListings(recentListings.slice(0, 5), intl.formatMessage({ id: 'landing_page.most_recent_label' })) }
          { recentListings && renderRecentListings(recentListings.slice(5, 10), intl.formatMessage({ id: 'landing_page.most_popular_label' })) }
          { renderBuyButton('bike', intl.formatMessage({ id: 'landing_page.buy_bike_button' })) }
          { recentPartListings?.length > 0 && renderRecentListings(recentPartListings, intl.formatMessage({ id: 'landing_page.most_recent_part_label' })) }
          { recentPartListings?.length > 0 && renderBuyButton('parts', intl.formatMessage({ id: 'landing_page.buy_parts_button' })) }
          
          <div className={css.infoSection}>
            <SectionInfo intl={intl}/>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
}

const { bool, object } = PropTypes

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  history: object.isRequired,
  location: object.isRequired,

  intl: intlShape.isRequired,
}

const mapStateToProps = state => {
  const {
    recentListingIds,
    recentPartListingsIds
  } = state.LandingPage

  const { currentUserFavoriteListingsIds, currentUser } = state.user

  const recentListings = getListingsById(state, recentListingIds)
  const recentPartListings = getListingsById(state, recentPartListingsIds)

  return {
    scrollingDisabled: isScrollingDisabled(state),
    recentListings: recentListings,
    recentPartListings: recentPartListings,
    likedListingsIds: currentUserFavoriteListingsIds,
    currentUserId: currentUser?.id?.uuid,
  }
}

const mapDispatchToProps = dispatch => ({
  addUserFavorite: (listingId, listingType) => dispatch(addCurrentUserFavoriteListing(listingId, listingType)),
  removeUserFavorite: (listingId) => dispatch(removeCurrentUserFavoriteListing(listingId)),
});

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent)

export default LandingPage
