import React from 'react'
import { filters } from '../../../config/marketplace-custom-config'
import classNames from 'classnames'
import css from './SectionCategories.module.css'

import { NamedLink } from '../../../components'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'

const CategoryCard = props => {
  const { name, categoryKey, icon, history } = props

  return (
    <NamedLink
      name="SearchPage"
      params={{ type: 'bike', category: categoryKey }}
      className={css.categoryCard}
      state={{ prevPath: history.location.pathname }}
    >
      <div className={css.categoryProperties}>
        <img className={css.categoryIcon} src={icon} />
        <span className={css.categoryName}>{name}</span>
      </div>
    </NamedLink>
  )  
}

const SectionCategories = props => {
  const { intl, history } = props

  const classes = classNames(css.root)
  const categories = filters.find(filter => filter.id == 'category').config.options

  return (
    <div className={classes}>
      { 
        categories.map(category => { 
          return <CategoryCard
            name={intl.formatMessage({ id: `categories.${category.key}` })}
            key={category.key}
            categoryKey={category.key}
            icon={category.logo}
            history={history}
          /> 
        }) 
      }
    </div>
  )
}

export default compose(withRouter)(SectionCategories)
